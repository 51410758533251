import { useEffect, useState } from "react";
import { db } from "../../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import "./FoundersList.css";
import { FaWhatsapp, FaLinkedinIn } from "react-icons/fa";
import Modal from "react-modal";

Modal.setAppElement("#root");

function FoundersList() {
  const [founders, setFounders] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState("");
  const foundersCollectionRef = collection(db, "founders");

  useEffect(() => {
    const fetchFounders = async () => {
      const data = await getDocs(foundersCollectionRef);
      const filteredData = data.docs.filter((doc) => doc.data().startupName);
      setFounders(filteredData.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    fetchFounders();
  }, [foundersCollectionRef]);

  const openWhatsApp = (phone) => {
    let text =
      "Hello 👋! I came across your profile on Founders & Friends and I'm really impressed by your journey. I'd love to connect and explore potential opportunities together.";
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        text
      )}&phone=+20${phone}`
    );
  };

  const openModal = (description) => {
    setSelectedDescription(description);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedDescription("");
  };

  return (
    <div className="founders-list">
      <h1 className="list-title">Meet the Founders</h1>
      <div className="card-container">
        {founders.map((founder) => (
          <div key={founder.id} className="founder-card">
            <div className="avatar-container">
              <div className="avatar-circle">
                <span>{founder.name.charAt(0)}</span>
              </div>
            </div>
            <h2 className="founder-name">{founder.name}</h2>
            <p className="founder-email">{founder.email}</p>
            {founder.whatsapp && (
              <div className="space-x-2 mt-4">
                <button
                  className="whatsapp-icon"
                  onClick={() => openWhatsApp(founder.whatsapp)}
                >
                  <FaWhatsapp size={24} />
                </button>
                {founder.linkedin && (
                  <button
                    className="linkedin-icon"
                    onClick={() => window.open(founder.linkedin, "_blank")}
                  >
                    <FaLinkedinIn size={24} />
                  </button>
                )}
              </div>
            )}
            {founder.startupName && (
              <div className="startup-info">
                <p className="startup-name">{founder.startupName}</p>
                <p className="startup-industry">{founder.industry}</p>
                <p className="startup-location">{founder.location}</p>
                {founder.description && (
                  <button
                    className="startup-details-button"
                    onClick={() => openModal(founder.description)}
                  >
                    Startup Details
                  </button>
                )}
                {founder.website && (
                  <p className="startup-website">
                    <a
                      href={founder.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Website
                    </a>
                  </p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Startup Details"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2 className="modal-title">Startup Details</h2>
        <p>{selectedDescription}</p>
        <button onClick={closeModal} className="modal-button">
          Close
        </button>
      </Modal>
    </div>
  );
}

export default FoundersList;
