// src/pages/LandingPage.js
import FounderForm from "../../components/FounderForm/FounderForm";
import "./LandingPage.css";

function LandingPage() {
  return (
    <div className="landing-page">
      <header className="landing-header">
        <h1 className="landing-title">Founders & Friends</h1>
        <p className="landing-subtitle">
          Connect with startup founders like you.
        </p>
      </header>
      <FounderForm />
    </div>
  );
}

export default LandingPage;
