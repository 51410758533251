import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase-config";
import { collection, addDoc, getDocs, deleteDoc } from "firebase/firestore";
import Modal from "react-modal";
import "./FounderForm.css";

Modal.setAppElement("#root");

function FounderForm() {
  const [hasStarted, setHasStarted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    whatsapp: "",
    linkedin: "",
    idea: "",
    startupName: "",
    industry: "",
    description: "",
    website: "",
  });
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const foundersCollectionRef = collection(db, "founders");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (hasStarted) {
      if (!formData.startupName)
        newErrors.startupName = "Startup Name is required";
      if (!formData.industry) newErrors.industry = "Industry is required";
      if (!formData.description)
        newErrors.description = "Description is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const createFounder = async () => {
    if (!validateForm()) return;
    const data = await getDocs(foundersCollectionRef);
    const existingData = data.docs.find(
      (doc) => doc.data().email === formData.email
    );
    if (existingData) {
      await deleteDoc(existingData.ref);
    }

    await addDoc(foundersCollectionRef, formData);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (!formData.startupName) {
      navigate("/aspiring-founders");
    } else {
      navigate("/founders");
    }
  };

  return (
    <div className="form-container">
      <h2 className="form-title">Register to Connect</h2>
      <p className="form-description">
        Let’s get to know you and your startup.
      </p>
      <p className="text-sm mb-8 px-6 text-gray-400 text-center font-bold">
        Re-register using same email if you want to update your information.
      </p>

      <input
        type="text"
        name="name"
        placeholder="Your Name"
        value={formData.name}
        onChange={handleInputChange}
        className="form-input"
        required
      />
      {errors.name && <p className="error-text">{errors.name}</p>}

      <input
        type="email"
        name="email"
        placeholder="Your Email"
        value={formData.email}
        onChange={handleInputChange}
        className="form-input"
        required
      />
      {errors.email && <p className="error-text">{errors.email}</p>}

      <input
        type="tel"
        name="whatsapp"
        placeholder="Whatsapp Number (optional)"
        value={formData.whatsapp}
        onChange={handleInputChange}
        className="form-input"
      />

      <input
        type="text"
        name="linkedin"
        placeholder="LinkedIn Profile (optional)"
        value={formData.linkedin}
        onChange={handleInputChange}
        className="form-input"
      />

      {!hasStarted && (
        <textarea
          type="text"
          name="idea"
          placeholder="Startup Idea (optional)"
          value={formData.idea}
          onChange={handleInputChange}
          className="form-input"
        />
      )}

      <div className="form-radio">
        <label className="items-center">Have you started your company?</label>
        <div>
          <label>
            <input
              type="radio"
              name="hasStarted"
              value="yes"
              checked={hasStarted === true}
              onChange={() => setHasStarted(true)}
            />{" "}
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="hasStarted"
              value="no"
              checked={hasStarted === false}
              onChange={() => setHasStarted(false)}
            />{" "}
            No
          </label>
        </div>
      </div>

      {hasStarted && (
        <div className="startup-info">
          <input
            type="text"
            name="startupName"
            placeholder="Startup Name"
            value={formData.startupName}
            onChange={handleInputChange}
            className="form-input"
          />
          {errors.startupName && (
            <p className="error-text">{errors.startupName}</p>
          )}

          <input
            type="text"
            name="industry"
            placeholder="Industry"
            value={formData.industry}
            onChange={handleInputChange}
            className="form-input"
          />
          {errors.industry && <p className="error-text">{errors.industry}</p>}

          <textarea
            type="text"
            name="description"
            placeholder="Description"
            value={formData.description}
            onChange={handleInputChange}
            className="form-input"
          />
          {errors.description && (
            <p className="error-text">{errors.description}</p>
          )}

          <input
            type="url"
            name="website"
            placeholder="Website (optional)"
            value={formData.website}
            onChange={handleInputChange}
            className="form-input"
          />
        </div>
      )}

      <button onClick={createFounder} className="submit-button">
        Register
      </button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Registration Successful"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Thank you for registering!</h2>
        <button onClick={closeModal} className="modal-button">
          Close
        </button>
      </Modal>
    </div>
  );
}

export default FounderForm;
