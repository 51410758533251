// src/components/Navbar.js
import { useState } from "react";
import "./Navbar.css";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-content">
        <div className="navbar-brand">Founders & Friends</div>

        <button className="menu-button" onClick={toggleMenu}>
          ☰
        </button>

        <ul className={`navbar-links ${isMenuOpen ? "open" : ""}`}>
          <li>
            <a href="/founders">Founders</a>
          </li>
          <li>
            <a href="/aspiring-founders">Aspiring Founders</a>
          </li>
          <li>
            <a href="/">Register</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
