// src/pages/AspiringFounders.js
import React, { useEffect, useState } from "react";
import { db } from "../../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import "./AspiringFoundersList.css";
import { FaWhatsapp, FaLinkedinIn } from "react-icons/fa";

function AspiringFoundersList() {
  const [aspiringFounders, setaspiringFounders] = useState([]);
  const foundersCollectionRef = collection(db, "founders");

  useEffect(() => {
    const fetchResources = async () => {
      const data = await getDocs(foundersCollectionRef);
      const filteredData = data.docs.filter((doc) => !doc.data().startupName);
      setaspiringFounders(
        filteredData.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };
    fetchResources();
  }, [foundersCollectionRef]);

  const openWhatsApp = (phone) => {
    let text =
      "Hello 👋! I came across your profile on Founders & Friends and I'm really impressed by your journey. I'd love to connect and explore potential opportunities together.";
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        text
      )}&phone=+20${phone}`
    );
  };

  return (
    <div className="aspiring-founders-container">
      <h1 className="title">Welcome, Future Founders!</h1>
      <p className="intro">
        You’ve taken the first step by registering. Although you haven’t started
        your company yet, we’re excited to support you on your journey!
      </p>
      <div className="resources">
        <div className="card-container">
          {aspiringFounders.map((founder) => (
            <div key={founder.id} className="founder-card">
              <div className="avatar-container">
                <div className="aspiring-avatar-circle bg-blue-400">
                  <span>{founder.name.charAt(0)}</span>
                </div>
              </div>
              <h2 className="founder-name">{founder.name}</h2>
              <p className="founder-email">{founder.email}</p>
              {founder.whatsapp && (
                <div className="space-x-2 mt-4">
                  <button
                    className="whatsapp-icon"
                    onClick={() => openWhatsApp(founder.whatsapp)}
                  >
                    <FaWhatsapp size={24} />
                  </button>
                  {founder.linkedin && (
                    <button
                      className="linkedin-icon"
                      onClick={() => window.open(founder.linkedin, "_blank")}
                    >
                      <FaLinkedinIn size={24} />
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="networking">
        <h2>Join Other Aspiring Founders</h2>
        <p>Connect with others who are also starting their journey!</p>
      </div>
    </div>
  );
}

export default AspiringFoundersList;
