// src/App.js
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import FoundersList from "./pages/FoundersList/FoundersList";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import AspiringFounders from "./pages/AspiringFoundersList/AspiringFoundersList";

function App() {
  return (
    <Router>
      <Navbar />
      <div className="h-20" />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/founders" element={<FoundersList />} />
        <Route path="/aspiring-founders" element={<AspiringFounders />} />
      </Routes>
      <div className="h-16" />
    </Router>
  );
}

export default App;
