// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  // apiKey: "AIzaSyAWaDq3DUB6OEWdNxF5a9KvtXZne69eepU",
  // authDomain: "founders-and-friends-48t1z.firebaseapp.com",
  // projectId: "founders-and-friends-48t1z",
  // storageBucket: "founders-and-friends-48t1z.appspot.com",
  // messagingSenderId: "377438466260",
  // appId: "1:377438466260:web:6c96276a5a0364b83e0b41",
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
